import {makeStyles, TextField} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
}));

export default function useCreateInputs(data, state, onChange) {
    /* TODO Add numeric validation */
    const classes = useStyles();

    const createInputs = () => {
        return Object.entries(data.fields)
            .map(([field, fieldData]) => {
                return (
                    <TextField
                        key={fieldData.id}
                        id={fieldData.id}
                        name={fieldData.name}
                        label={fieldData.label}
                        placeholder={fieldData.placeholder}
                        variant="outlined"
                        value={state[field]}
                        onChange={onChange}
                        className={clsx(classes.margin)}
                    />
                )
            });
    }

    return {
        createInputs
    }
}
