import GenericCalculation from "../GenericCalculation";

export default function VolumetricFlowRate({data}) {
    return (
        <div>
            {/* TODO Add extra calculations for determining area */}
            <GenericCalculation data={data} />
        </div>
    );
}
