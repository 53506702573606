import React from 'react';
import Voltage from './Components/Electrical/Voltage';
import data from "./data";

const Air = () => {
  return (
    <p></p>
  );
};
const Hydronic = () => {
  return (
    <p></p>
  );
};
const Electrical = () => {
  return (
    <p></p>
  );
};

const Routes = [
  {
    path: '/air',
    sidebarName: 'Air',
    component: Air,
    subitems: [
      {
        id: 11,
        name: "Airflow & Velocity",
        path: '/air/airflow-velocity',
      },
      {
        id: 12,
        name: "Air Temperature",
        path: '/air/air-temperature',
      },
      {
        id: 13,
        name: "Heat Transfer",
        path: '/air/heat-transfer',
      },
      {
        id: 14,
        name: "Fan Equations",
        path: '/air/fan-equations',
      },
      {
        id: 15,
        name: "Sheave Equations",
        path: '/air/sheave-equations',
      },
    ]
  },
  {
    path: '/hydronic',
    sidebarName: 'Hydronic',
    component: Hydronic,
    subitems: [

      {
        id: 21,
        name: "Pump Laws",
        path: '/hydronic/pump-laws',
      },
      {
        id: 22,
        name: "Water Horsepower",
        path: '/hydronic/water-horsepower',
      },
      {
        id: 23,
        name: "Brake Horsepower",
        path: '/hydronic/brake-horsepower',
      },
      {
        id: 24,
        name: "Pump Efficiency",
        path: '/hydronic/pump-efficiency',
      },
      {
        id: 25,
        name: "ΔP, Cv and GPM",
        path: '/hydronic/gmp',
      },
      {
        id: 26,
        name: "NPSHA",
        path: '/hydronic/npsha',
      },
      {
        id: 27,
        name: "Heat Transfer",
        path: '/hydronic/heat-transfer',
      },
      {
        id: 28,
        name: "Boiler",
        path: '/hydronic/boiler',
      },

    ]
  },
  {
    path: '/electrical',
    sidebarName: 'Electrical',
    component: Electrical,
    subitems: [
      {
        id: 31,
        name: "Power",
      },
      {
        id: 32,
        name: "Volts/Amps/Ohms",
        component: Voltage,
        path: 'electrical/voltage',
        data: data['voltage']
      }
    ]
  },
];

export default Routes;
