import {totalPressure} from "./Formulas/Air/AirflowAndVelocity/TotalPressure";
import {volumetricFlowRate} from "./Formulas/Air/AirflowAndVelocity/VolumetricFlowRate";
import {voltage} from "./Formulas/Electrical/Voltage";
import {resistance} from "./Formulas/Electrical/Resistance";
import {current} from "./Formulas/Electrical/Current";
import {power} from "./Formulas/Electrical/Power";
import {pumpLaw1A} from "./Formulas/Hydronic/PumpLaws/pumpLaw1A";
import {pumpLaw1B} from "./Formulas/Hydronic/PumpLaws/pumpLaw1B";
import {pumpLaw2} from "./Formulas/Hydronic/PumpLaws/pumpLaw2";
import {pumpLaw3} from "./Formulas/Hydronic/PumpLaws/pumpLaw3";

let data = {
    totalPressure: {
        title: 'Total Pressure',
        fields: {
            velocityPressure: {
                id: 'velocity',
                name: 'velocityPressure',
                label: 'Velocity Pressure (VP)',
                placeholder: 'Enter velocity pressure [in wg]'
            },
            staticPressure: {
                id: 'static-pressure',
                name: 'staticPressure',
                label: 'Static Pressure (SP)',
                placeholder: 'Enter static pressure [in wg]'
            }
        },
        formula: totalPressure,
        unit: 'in wg',
        outputLabel: 'Calculated Total Pressure'
    },

    volumetricFlowRate: {
        title: 'Volumetric Flow Rate',
        fields: {
            velocity: {
                id: 'velocity',
                name: 'velocity',
                label: 'Velocity (FPM)',
                placeholder: 'Velocity (FPM)'
            },
            area: {
                id: 'area',
                name: 'area',
                label: 'Area (ft^2)',
                placeholder: 'Area (ft^2)'
            }
        },
        formula: volumetricFlowRate,
        unit: 'CFM',
        outputLabel: 'Calculated Volumetric Flow Rate'
    },

    voltage: {
        title: 'Voltage',
        fields: {
            current: {
                id: 'current',
                name: 'current',
                label: 'Current (I)',
                placeholder: 'Current (I) in Amps',
            },
            resistance: {
                id: 'resistance',
                name: 'resistance',
                label: 'Resistance (\u2126)',
                placeholder: 'Resistance (\u2126) in Ohms',
            }
        },
        formula: voltage,
        unit: 'V',
        outputLabel: 'Calculated Voltage'
    },

    resistance: {
        title: 'Resistance',
        fields: {
            voltage: {
                id: 'voltage',
                name: 'voltage',
                label: 'Voltage (E)',
                placeholder: 'Volts (E)',
            },
            current: {
                id: 'current',
                name: 'current',
                label: 'Current (I)',
                placeholder: 'Current (I) in Amps',
            },
        },
        formula: resistance,
        unit: '\u2126',
        outputLabel: 'Calculated Resistance'
    },

    current: {
        title: 'Current',
        fields: {
            voltage: {
                id: 'voltage',
                name: 'voltage',
                label: 'Voltage (E)',
                placeholder: 'Volts (E)',
            },
            resistance: {
                id: 'resistance',
                name: 'resistance',
                label: 'Resistance (\u2126)',
                placeholder: 'Resistance (\u2126) in Ohms',
            },
        },
        formula: current,
        unit: 'A',
        outputLabel: 'Calculated Current'
    },

    power: {
        title: 'Power',
        fields: {
            voltage: {
                id: 'voltage',
                name: 'voltage',
                label: 'Voltage (E)',
                placeholder: 'Volts (E)',
            },
            current: {
                id: 'current',
                name: 'current',
                label: 'Current (I)',
                placeholder: 'Current (I) in Amps',
            },
        },
        formula: power,
        unit: 'W',
        outputLabel: 'Calculated Power',
        conversions: [
            {
                factor: 0.001,
                unit: 'kW'
            }
        ]
    },

    pumpLaw1A: {
        title: "Pump Law 1A (Enter 3 values)",
        fields: {
            gpm1: {
                id: 'gpm1',
                name: 'gpm1',
                label: 'GPM1',
                placeholder: 'GPM1',
            },
            gpm2: {
                id: 'gpm2',
                name: 'gpm2',
                label: 'GPM2',
                placeholder: 'GPM2',
            },
            rpm1: {
                id: 'rpm1',
                name: 'rpm1',
                label: 'RPM1',
                placeholder: 'RPM1',
            },
            rpm2: {
                id: 'rpm2',
                name: 'rpm2',
                label: 'RPM2',
                placeholder: 'RPM2',
            }
        },
        formula: pumpLaw1A,
        outputLabel: 'Calculated Pump Law 1A Result',
    },

    pumpLaw1B: {
        title: 'Pump Law 1B (Enter 3 values)',

        fields: {
            gpm1: {
                id: 'gpm1',
                name: 'gpm1',
                label: 'GPM1',
                placeholder: 'GPM1',
            },
            gpm2: {
                id: 'gpm2',
                name: 'gpm2',
                label: 'GPM2',
                placeholder: 'GPM2',
            },
            dimp1: {
                id: 'dimp1',
                name: 'dimp1',
                label: 'd imp1',
                placeholder: 'Enter [in]',
            },
            dimp2: {
                id: 'dimp2',
                name: 'dimp2',
                label: 'd imp2',
                placeholder: 'Enter [in]',
            }
        },
        formula: pumpLaw1B,
        outputLabel: 'Calculated Pump Law 1B Result'
    },

    pumpLaw2: {
        title: 'Pump Law 2 (Enter 3 values)',
        fields: {
            gpm1: {
                id: 'gpm1',
                name: 'gpm1',
                label: 'GPM1',
                placeholder: 'GPM1',
            },
            gpm2: {
                id: 'gpm2',
                name: 'gpm2',
                label: 'GPM2',
                placeholder: 'GPM2',
            },
            h1: {
                id: 'h1',
                name: 'h1',
                label: 'H1',
                placeholder: 'H1',
            },
            h2: {
                id: 'h2',
                name: 'h2',
                label: 'H2',
                placeholder: 'H2',
            }
        },
        formula: pumpLaw2,
        outputLabel: 'Calculated Pump Law 2 Result'
    },

    pumpLaw3: {
        title: 'Pump Law 3 (Enter 3 values)',
        fields: {
            gpm1: {
                id: 'gpm1',
                name: 'gpm1',
                label: 'GPM1',
                placeholder: 'GPM1',
            },
            gpm2: {
                id: 'gpm2',
                name: 'gpm2',
                label: 'GPM2',
                placeholder: 'GPM2',
            },
            bhp1: {
                id: 'bhp1',
                name: 'bhp1',
                label: 'BHP1',
                placeholder: 'BHP1',
            },
            bhp2: {
                id: 'bhp2',
                name: 'bhp2',
                label: 'BHP2',
                placeholder: 'BHP2',
            }
        },
        formula: pumpLaw3,
        outputLabel: 'Calculated Pump Law 3 Result'

    }

}

export default data;
