import GenericCalculation from "../GenericCalculation";

export default function Power({data}) {

    return (
        <div>
            <GenericCalculation data={data} />
        </div>
    );
}
