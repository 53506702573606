export const pumpLaw3 = ({gpm1 = null, gpm2 = null, bhp2 = null, bhp1 = null}) => {

    if (gpm1 && gpm2 && bhp1 && !bhp2) {
        return bhp1 * ((gpm2 / gpm1) ** 3)
    }

    if (gpm1 && gpm2 && bhp2 && !bhp1) {
        return bhp2 / ((gpm2 / gpm1) ** 3);
    }

    if (gpm1 && bhp1 && bhp2 && !gpm2) {
        return Math.cbrt(bhp2 / bhp1) * gpm1;
    }

    if (gpm2 && bhp1 && bhp2 && !gpm1) {
        return gpm2 / Math.cbrt(bhp2 / bhp1);
    }

    return null;
};
