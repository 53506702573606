export const pumpLaw2 = ({gpm1 = null, gpm2 = null, h1 = null, h2 = null}) => {

    if (gpm1 && gpm2 && h1 && !h2) {
        return h1 * ((gpm2 / gpm1) ** 2)
    }

    if (gpm1 && gpm2 && h2 && !h1) {
        return h2 / ((gpm2 / gpm1) ** 2);
    }

    if (gpm1 && h1 && h2 && !gpm2) {
        return Math.sqrt(h2 / h1) * gpm1;
    }

    if (gpm2 && h1 && h2 && !gpm1) {
        return gpm2 / Math.sqrt(h2 / h1);
    }

    return null;
};
