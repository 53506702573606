export const pumpLaw1A = ({gpm1 = null, gpm2 = null, rpm1 = null, rpm2 = null}) => {

    if (gpm1 && gpm2 && rpm1 && !rpm2) {
        return rpm1 * (gpm2 / gpm1)
    }

    if (gpm1 && gpm2 && rpm2 && !rpm1) {
        return rpm2 / (gpm2 / gpm1);
    }

    if (gpm1 && rpm1 && rpm2 && !gpm2) {
        return (rpm2 / rpm1) * gpm1;
    }

    if (gpm2 && rpm1 && rpm2 && !gpm1) {
        return gpm2 / (rpm2 / rpm1);
    }

    return null;
};
