import GenericCalculation from "../../GenericCalculation";

export default function PumpLaw2({data}) {

    return (
        <div>
            <GenericCalculation data={data} />
        </div>
    );
}
