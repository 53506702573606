import { Button } from "@material-ui/core";
import useCalculations from "../hooks/useCalculations";
import useCreateInputs from "../hooks/useCreateInputs";
import OutputResult from "../Components/OutputResult";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(() => ({
    calculateWrapper: {
        margin: 9,
    },
    resultWrapper: {
        marginLeft: 9,
        marginTop: 20,
    },
    paper: {
        marginLeft: 9,
        padding: 10,
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

export default function GenericCalculation({ data }) {
    const { state, result, setResult, onChange } = useCalculations(data.fields);
    const { createInputs } = useCreateInputs(data, state, onChange);
    const classes = useStyles();

    const getUnitFromState = (obj) => Object.entries(obj).filter(([key, value]) => value === null || value === '')[0][0].toUpperCase();

    return (
        <>
            <h3 className={classes.calculateWrapper}>{data.title}</h3>
            <form noValidate autoComplete="off">
                <Paper elevation={3} className={classes.paper}>

                    <div>
                        {createInputs()}
                    </div>

                <div className={classes.calculateWrapper}>
                    <Button variant="contained" color="primary"
                        onClick={() => setResult(
                            data.formula(state) || null
                        )}>
                        Calculate
                    </Button>
                    </div>
                </Paper>
                <div className={classes.resultWrapper}>
                    <OutputResult
                        label={data.outputLabel}
                        result={result}
                        unit={data.unit || (result && getUnitFromState(state))}
                        conversions={data.conversions}
                    />
                </div>
            </form>
        </>
    );
}
