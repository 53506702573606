import {useState} from "react";
import {toObj} from "../utils/toObj";

export default function useCalculations (fields = []) {
    const [state, setState] = useState(toObj(Object.keys(fields)));

    const [result, setResult] = useState(null);

    function onChange(event) {
        const { name, value } = event.target;
        setResult(null);
        setState(prevState => ({ ...prevState, [name]: value }));
    }

    return {
        state,
        result,
        setResult,
        onChange
    }
}
