import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import CloudIcon from '@material-ui/icons/Cloud';
import OpacityIcon from '@material-ui/icons/Opacity';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Routes from './Routes';
import { NavLink, withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(9),
        color: '#757575'
    },
    air: {
        color: '#23B070'
    },
    hydro: {
        color: '#383BB2'
    },
    electric: {
        color: '#FFA633'
    },
}));

const NestedMenu = (props) => {
    const classes = useStyles();
    const [Air, setAirOpen] = useState(false);
    const [Hydronic, setHydroOpen] = useState(false);
    const [Electrical, setElectricalOpen] = useState(false);

    const activeRoute = (routeName) => {
        return props.location.pathname === routeName ? true : false;
    }

    const handleClick = (title) => {
        if (title === 'Air' || title === 'Hydronic' || title === 'Electrical') {
            let functionMapping = {
                "Hydronic": setHydroOpen,
                "Air": setAirOpen,
                "Electrical": setElectricalOpen
            }
            functionMapping[title](true)
            let filteredRoutes = Routes.filter(route => route.sidebarName !== title)
            filteredRoutes.forEach(route =>
                functionMapping[route.sidebarName](false)
            )
        }
    }

    return (
        <List component="nav">
            {Routes.map((prop, key) => {
                return (
                    <NavLink to={prop.path} style={{ textDecoration: 'none' }} key={key}>
                        <div>
                            <ListItem button key={key * 65} onClick={() => handleClick(prop.sidebarName)} selected={activeRoute(prop.path)}>
                                <ListItemIcon>
                                    {prop.sidebarName === 'Air' && <CloudIcon className={classes.air} />}
                                    {prop.sidebarName === 'Hydronic' && <OpacityIcon className={classes.hydro} />}
                                    {prop.sidebarName === 'Electrical' && <FlashOnIcon className={classes.electric} />}
                                </ListItemIcon>
                                <ListItemText primary={prop.sidebarName} />
                                <ExpandMore />
                            </ListItem>
                            <Collapse
                                key={prop.id}
                                in={
                                    prop.sidebarName === 'Air' ? Air :
                                        prop.sidebarName === 'Hydronic' ? Hydronic : Electrical
                                }
                                timeout="auto"
                                unmountOnExit
                            >
                                <List disablePadding>
                                    {prop.subitems.map(
                                        subitem => {
                                            return (
                                                <ListItem button key={subitem.id} className={classes.nested} onClick={() => handleClick(subitem.name)} selected={activeRoute(subitem.path)}>
                                                    <ListItemText key={subitem.id} primary={subitem.name} />
                                                </ListItem>
                                            );
                                        }
                                    )}
                                </List>
                            </Collapse>{" "}
                        </div>
                    </NavLink>
                );
            })}
        </List>
    );
}
export default withRouter(NestedMenu);