import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    resultWrapper: {
        fontSize: 18,
        color: '#a00037'
    }
}));
export default function OutputResult({ label, result, unit, conversions }) {
    const classes = useStyles();

    return (
        <>
            <div >
                {label}: <b className={classes.resultWrapper}>{result || ''} {result ? unit : ''}</b>
            </div>
            {conversions && conversions.length && conversions.map((conversion, key) => {
                return (
                    <div key={key}>
                        {result !== null ? result * conversion.factor : ''} { result !== null ? conversion.unit : ''}
                    </div>
                )
            })}
        </>
    );
}
