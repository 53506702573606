import Power from './Components/Electrical/Power';
import Voltage from './Components/Electrical/Voltage';
import data from './data';
import VolumetricFlowRate from "./Components/Air/VolumetricFlowRate";
import TotalPressure from "./Components/Air/TotalPressure";
import PumpLaw1A from "./Components/Hydronic/PumpLaws/PumpLaw1A";
import PumpLaw1B from "./Components/Hydronic/PumpLaws/PumpLaw1B";
import PumpLaw2 from "./Components/Hydronic/PumpLaws/PumpLaw2";
import PumpLaw3 from "./Components/Hydronic/PumpLaws/PumpLaw3";

export const subCategories = {
  'air': {
    subitems: [{
      id: 1,
      name: "Volumetric Flow Rate",
      component: VolumetricFlowRate,
      path: 'air/volume',
      data: data['volumetricFlowRate']
    },
    {
      id: 2,
      name: "Total Pressure",
      component: TotalPressure,
      path: 'air/pressure',
      data: data['totalPressure']
    }]
  },
  'hydronic': {
    subitems: [{
      id: 1,
      name: "Pump Law 1A",
      component: PumpLaw1A,
      path: 'hydro/pump-law-1a',
      data: data['pumpLaw1A']
    },
    {
      id: 2,
      name: "Pump Law 1B",
      component: PumpLaw1B,
      path: 'hydro/pump-law-1b',
      data: data['pumpLaw1B']
    },
    {
      id: 3,
      name: "Pump Law 2",
      component: PumpLaw2,
      path: 'hydro/pump-law-2',
      data: data['pumpLaw2']
    },
    {
      id: 4,
      name: "Pump Law 3",
      component: PumpLaw3,
      path: 'hydro/pump-law-3',
      data: data['pumpLaw3']
    }]
  },
  'electrical': {
    subitems: [{
      id: 1,
      name: "Power",
      component: Power,
      path: 'electrical/power',
      data: data['power']
    },
    {
      id: 2,
      name: "Volts",
      component: Voltage,
      path: 'electrical/voltage',
      data: data['voltage']
    }]
  },
};

