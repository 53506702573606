import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { subCategories } from "../../subCategories";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`category-tabpanel-${index}`}
            aria-labelledby={`category-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `category-tab-${index}`,
        'aria-controls': `category-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        backgroundColor: '#383BB2',
        flexGrow: 1,

    },
    tabTitle: {
        textTransform: 'lowercase',
    },
    definitionTitle: {
        marginBottom: 5,
        marginLeft: 8,
        marginTop: 0,
        color: '#3f51b5'
    },
    definition: {
        marginLeft: 8,
        marginTop: 0,
        marginBottom: 25
    }
}));

export default function GenericTab({ title }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>

            <AppBar position="static" className={classes.appBar}>
                <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    className={classes.tabTitle}
                    value={value} onChange={handleChange}
                    aria-label="sub-category tabs"
                >
                    {subCategories[title].subitems.map((prop, key) => {
                        return (
                            [
                                <Tab key={key} label={prop.name} {...a11yProps(key)} />

                            ]
                        )
                    })}
                </Tabs>
            </AppBar>
            {subCategories[title].subitems.map((prop, key) => {
                return (
                    <div key={key}>
                        <TabPanel value={value} index={key}>
                            <h2 className={classes.definitionTitle}>Calculations for {prop.name}</h2>
                            <p className={classes.definition}>Enter valuses, then press <i>Calculate</i></p>
                            {React.createElement(prop.component, {
                                data: prop.data
                            })}
                        </TabPanel>
                    </div>
                )
            })}
        </div>
    );
}