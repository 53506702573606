export const pumpLaw1B = ({gpm1 = null, gpm2 = null, dimp1 = null, dimp2 = null}) => {

    if (gpm1 && gpm2 && dimp1 && !dimp2) {
        return dimp1 * (gpm2 / gpm1)
    }

    if (gpm1 && gpm2 && dimp2 && !dimp1) {
        return dimp2 / (gpm2 / gpm1);
    }

    if (gpm1 && dimp1 && dimp2 && !gpm2) {
        return (dimp2 / dimp1) * gpm1;
    }

    if (gpm2 && dimp1 && dimp2 && !gpm1) {
        return gpm2 / (dimp2 / dimp1);
    }

    return null;
};
